import {Component, Input} from '@angular/core';
import {Subscription} from "rxjs";

import {ListsService} from "@app/_services/lists.service";

@Component({
  selector: 'pagination',
  templateUrl: 'pagination.component.html',
  styleUrls: ['pagination.component.scss']
})
export class PaginationComponent {
  @Input() listId?;
  public data: any;
  public pages = [];
  resultSubscription?: Subscription;

  public maxPages = 2;

  constructor(private listsService: ListsService) {
  }

  ngOnInit() {
    this.resultSubscription = this.listsService.result(this.listId).subscribe(data => {
      this.data = data?.meta?.pagination;
      this.makePages();
    });
  }
  ngOnDestroy() {
    this.resultSubscription?.unsubscribe();
  }


  get shownFrom() {
    return (this.data?.currentPage - 1) * this.data?.perPage + 1;
  }
  get shownTo() {
    let val = this.data?.currentPage * this.data?.perPage;
    return (val < this.data?.total) ? val : this.data?.total;
  }
  get currentPage() {
    return this.listsService.result(this.listId).value?.meta?.pagination?.currentPage;
  }
  get total() {
    return this.data?.totalPages || 1;
  }
  get page() {
    return this.data?.currentPage || 1;
  }
  get from() {
    let res = this.page - this.maxPages;
    if (this.page > (this.total - this.maxPages)) res -= (this.page + this.maxPages - this.total);
    return (res > 1) ? res : 1;
  }
  get to() {
    let res = this.page + this.maxPages;
    if (this.page < this.maxPages) res += this.maxPages - this.page;
    return (res < this.total) ? res : this.total;
  }


  makePages() {
    this.pages = [];
    if (this.total > 1) {
      for (let i = this.from; i <= this.to; i++) {
        this.pages.push(i);
      }
    }
  }
  setPage(page: number) {
    this.listsService.setPage(page, this.listId);
  }
  nextPage() {
    this.listsService.setPage(this.currentPage + 1, this.listId);
  }
}
