<router-outlet name="auth"></router-outlet>
<router-outlet name="slider"></router-outlet>

<div class="wrapper" [class.this-root-page] = "isRootPage">
  <header class="space"></header>
  <jumbotron></jumbotron>
  <div class="space content-sapce">
    <div class="content-grid layout-corral">
      <left-content></left-content>
      <div class="main-content">
      <router-outlet></router-outlet>
      </div>
      <right-content></right-content>
    </div>
  </div>
  <footer class="space"></footer>
</div>
<fullscreen-gallery></fullscreen-gallery>
<cms-dialog dir="ltr"></cms-dialog>
