import {Component, Input} from '@angular/core';
import {Subscription} from "rxjs";
import {FormsService, ListsService} from "@app/_services";
import {PublicationsService} from "@app/_services/publications.service";
import {PagesService} from "@app/_services/pages.service";

@Component({
  selector: 'publications-list',
  templateUrl: 'publications-list.component.html',
  styleUrls: ['publications-list.component.scss']
})
export class PublicationsListComponent {
  @Input() page: any;
  public publications = <any>[];

  controlsSubscription?: Subscription;
  resultsSubscription?: Subscription;

  public result: any;

  constructor(private publicationsService: PublicationsService, private pagesService: PagesService, private listsService: ListsService, private formsService: FormsService) {
  }

  get listId() {
    return 'publications-list';
  }

  get editMode() {
    return this.pagesService.editMode;
  }


  ngOnInit() {
    this.controlsSubscription = this.listsService.controls(this.listId).subscribe(controls => {
      this.fetch(controls);
    });
    this.resultsSubscription = this.listsService.result(this.listId).subscribe(res => {
      this.publications = res?.data;
    });
  }

  ngOnChanges() {
    this.listsService.refresh(this.listId);
  }

  ngOnDestroy() {
    this.controlsSubscription?.unsubscribe();
    this.resultsSubscription?.unsubscribe();
  }


  add() {
    this.formsService.createModel('publication', {extraProps: {page: this.page?.id}}, this.listId);
  }

  fetch(controls: any) {
    controls.filters.page = this.page?.id;
    let include = ['posters'];
    let params = {page: controls.page || 1, filters: JSON.stringify(controls.filters), include: include.join(',')};
    this.publicationsService.list(params).subscribe(result => {
      this.listsService.result(this.listId).next(result);
    });
  }
}
