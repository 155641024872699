import {Component, Input} from '@angular/core';
import {FormsService, ListsService} from "@app/_services";
import {RegistryEntriesService} from "@app/_services/registry-entries.service";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'registry-entry',
  templateUrl: 'registry-entry.component.html',
  styleUrls: ['registry-entry.component.scss']
})
export class RegistryEntryComponent {
  @Input() registry: any;
  @Input() category: any;
  @Input() entry: any;
  @Input() editMode: boolean;

  public isExpanded = false;

  constructor(private entriesService: RegistryEntriesService, private formsService: FormsService, private listsService: ListsService, private datePipe: DatePipe) {
  }


  get operationsListId() {
    return this.entry.id;
  }
  get parentListId() {
    return (this.category?.id || this.registry?.id) + '-entries';
  }

  get isExpandable() {
    return this.registryOptions?.properties || this.registryOptions?.operations
  }

  get title() {
    return this.entry.number ? `${this.entry.number} «${this.entry.name}»` : this.entry.name;
  }
  get activeSince() {
    return this.entry.activeSince ? this.datePipe.transform(this.entry.activeSince, 'dd.MM.yyyy') : null;
  }
  get activeTill() {
    return this.entry.activeTill ? this.datePipe.transform(this.entry.activeTill, 'dd.MM.yyyy') : null;
  }
  get asset() {
    return this.entry.asset?.data;
  }
  get link() {
    return this.asset?.links?.open || this.entry.link;
  }

  get registryOptions() {
    return this.registry?.type?.options || this.entry?.registry?.data.type?.options;
  }


  ngOnInit() {

  }


  add() {
    if (this.isExpandable) this.isExpanded = true;
    this.formsService.createModel('entryOperation', {extraProps: {entry: this.entry.id}}, this.operationsListId);
  }

  edit() {
    this.formsService.editModel('registryEntry', this.entry.id, {extraProps: {registry: this.registry?.id}}, this.parentListId);
  }

  delete() {
    if (confirm('r u sure?')) {
      this.entriesService.delete(this.entry.id).subscribe(res => {
        this.listsService.refresh(this.parentListId);
      });
    }
  }


  toggle() {
    this.isExpanded = !this.isExpanded;
  }

}
