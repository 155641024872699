<div class="image">
  <img  *ngIf="image?.links?.thumb" [src]="image?.links?.thumb" />
</div>
<div class="title" *ngIf="name">{{firstName}}<br/>{{lastName}}</div>
<div class="subtitle" *ngIf="position">{{position}}</div>
<div class="documents list default" *ngIf="documents?.length">
  <div class="items">
    <div class="item" *ngFor="let document of documents">
      <!--      <file-ico [type]="document.extension"></file-ico>-->
      <div class="value">
        <a [href]="document.links?.open" target="_blank">{{document.name}}</a>
        <div class="description">{{document.description}}</div>
      </div>
    </div>
  </div>
</div>
<div class="phone" *ngIf="phone">{{phone}}</div>
<div class="email" *ngIf="email">{{email}}</div>
<div class="html" [innerHtml]="html"></div>
<div class="details" *ngIf="textOnly" (click)="showModal=true">
  <a>{{about}}<ico ico="chevron_right_24"></ico></a>
</div>
<modal *ngIf="showModal" (close)="showModal=false">
  <h4 header>{{name}}</h4>
  <img  *ngIf="image?.links?.thumb" [src]="image?.links?.thumb" />
  <div class="subtitle" *ngIf="position">{{position}}</div>
  <div class="phone" *ngIf="phone">{{phone}}</div>
  <div class="email" *ngIf="email">{{email}}</div>
  <div [innerHtml]="html"></div>

</modal>

