import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '@environments/environment';
import {Observable, BehaviorSubject} from "rxjs";
import {LicenceService} from "@app/_services/licence.service";
import { DialogService } from '@app/_services/dialog.service';


@Injectable({providedIn: 'root'})
export class PagesService {
  public currentPageSubject = new BehaviorSubject<any>(null);
  public rootPagesSubject = new BehaviorSubject<any>(null);
  public metaSubject = new BehaviorSubject<any>({title: '', description: '', keywords: ''});
  public editModeSubject = new BehaviorSubject<boolean>(false);
  public showDeletedSubject = new BehaviorSubject<boolean>(false);
  public menuSelectedLink: string;

  public rootPage:any = {link:'', slug:environment.defaultLocale};

  constructor(private http: HttpClient, private licenceService: LicenceService, private dialog: DialogService) {
    //this.find('/').subscribe(res => {this.rootPages = res.data});
  }

  get currentPage() {
    return this.currentPageSubject.value;
  }
  set currentPage(val: any) {
    this.currentPageSubject.next(val);
    this.rootPage = val?.parents?.data[0] || val;
    this.setMetaFromPage(val);
  }
  get rootPages() {
    return this.rootPagesSubject.value;
  }
  set rootPages(val: any) {
    this.rootPagesSubject.next(val);
  }

  get isRtl() {
    return ['ar'].indexOf(this.rootPage?.slug) !== -1;
  }

  get editMode() {
    return this.editModeSubject.value;
  }
  set editMode(value: boolean) {
    if (value) {
      let error = this.licenceService.checkEditAvailability(this.currentPage);
      if (error) {
        this.dialog.alert(error);
        return;
      }
    }
    this.editModeSubject.next(value);
  }

  get showDeleted() {
    return this.showDeletedSubject.value;
  }
  set showDeleted(value: boolean) {
    this.showDeletedSubject.next(value);
  }


  root(params?: any): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/pages/root`, {params: params});
  }

  find(url: string, params?: any): Observable<any> {
    if (!params) params = {};
    params.url = url;
    return this.http.get(`${environment.apiUrl}/api/pages/find`, {params: params});
  }

  list(params?: any): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/pages`, {params: params});
  }

  show(id: string, params?: any): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/api/pages/${id}`, {params: params});
  }

  delete(id: string): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/api/pages/${id}`);
  }

  restore(id: string, data: any): Observable<any> {
    return  this.http.patch(`${environment.apiUrl}/api/pages/restore/${id}`, data);
  }

  deleteBackground(id: string): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/api/pages/background/${id}`);
  }

  move(id: string, data: any): Observable<any> {
    return this.http.put(`${environment.apiUrl}/api/pages/move/${id}`, data);
  }

  clone(id: string, data: any): Observable<any> {
    return this.http.put(`${environment.apiUrl}/api/pages/clone/${id}`, data);
  }

  setMetaFromPage(page: any) {
    this.setMeta({title: page?.title || page?.name, description: page?.description || '', keywords: page?.keywords || ''});
  }
  setMeta(meta: any) {
    this.metaSubject.next(meta);
  }
}
