import { Component, Input } from '@angular/core';
import {ObjectsService} from "@app/_services";
import { Router } from '@angular/router';

@Component({
  selector: 'cards-item-default',
  templateUrl: './cards-item-default.component.html',
  styleUrls: ['./cards-item-default.component.scss']
})
export class CardsItemDefaultComponent {

  constructor(
    private objectsService: ObjectsService,
    private router: Router
  ) {}


  @Input() card:any;

  ngOnInit(){

  }

  get style() {
    return this.objectsService.getValue(this.card, 'card-style').name;
  }
  get header() {
    return this.objectsService.getValue(this.card, 'header');
  }
  get subheader() {
    return this.objectsService.getValue(this.card, 'subheader');
  }
  get text() {
    return this.objectsService.getValue(this.card, 'text');
  }
  get image() {
    return this.objectsService.getValue(this.card, 'image')?.links?.full+'?width=500';
  }
  get link() {
    return this.objectsService.getValue(this.card, 'link');
  }

  navigate(){
    console.log(this.link)
    if(this.link) this.router.navigate([this.link]).then()
  }


}
