import {Component} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {Subscription} from "rxjs";
import {PagesService} from "@app/_services/pages.service";
import {ListsService} from "@app/_services";
import {Title} from "@angular/platform-browser";

@Component({
  templateUrl: 'page.component.html',
  styleUrls: ['page.component.scss']
})
export class PageComponent {
  public page: any;
  public loading = false;
  private url: string;
  private inited = false;
  routeSubscription?: Subscription;
  listSubscription: Subscription;

  constructor(
    private router: Router,
    private pagesService: PagesService,
    private listsService: ListsService,
    private titleService:Title){
    this.routeSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) this.onNavigationEnd(event);
    });
  }


  get editMode() {
    return this.pagesService.editMode;
  }

  get permissions() {
    return this.page?.permissions;
  }

  get isEditable() {
    return this.permissions?.edit || this.permissions?.anything;
  }


  ngOnInit() {
    this.listSubscription = this.listsService.controls().subscribe(res => {
      this.inited ? this.fetch() : this.inited = true;
    });
  }

  ngOnDestroy() {
    this.routeSubscription?.unsubscribe();
    this.listSubscription?.unsubscribe()
  }

  onNavigationEnd(event: NavigationEnd) {
    let url = event.url.split('(')[0].split('?')[0];
    if (url !== this.url) {
      this.url = url;
      this.fetch();
      this.pagesService.editMode = false;
    }
  }

  fetch() {
    this.loading = true;
    let include = [
      'parents.children',
      'parents.picture',
      'children',
      'sections.type',
      'sections.groups.fields.value',
      'sections.objects.groups.fields.value',
      'sidebars.groups.fields.value',
      'sidebars.type',
      'permissions',
      'picture',
      'posters'
    ];
    if (this.url == '/certification/voluntary/main') this.url = '/sertifikaciya/dobrovolnaya-sertifikaciya'
    this.pagesService.find(this.url, {include: include.join(',')}).subscribe(res => {
      this.page = res?.data;
      if (this.page) {
        this.pagesService.currentPage = this.page;
        // this.titleService.setTitle(this.page.title||this.page.h1||this.page.name)
      }
      this.loading = false;
    }, error => {
      this.loading = false;
    });
  }
}
