import {Component, Input} from '@angular/core';
import {ObjectsService} from "@app/_services";
import {PagesService} from "@app/_services/pages.service";

@Component({
  selector: 'cards-item-persons',
  templateUrl: 'cards-item-persons.component.html',
  styleUrls: ['cards-item-persons.component.scss']
})
export class CardsItemPersonsComponent {
  @Input() card: any;
  public showModal = false;

  constructor(
    private objectsService: ObjectsService,
    public pagesService: PagesService
    ) {
  }

  get image() {
    return this.objectsService.getValue(this.card, 'image');
  }
  get name() {
    return this.objectsService.getValue(this.card, 'person-name');
  }
  get firstName(){
    return this.name.split(' ').slice(0,2).join(' ')
  }
  get lastName(){
    return this.name.split(' ').slice(2)[0]
  }
  get position() {
    return this.objectsService.getValue(this.card, 'person-position');
  }
  get documents() {
    return this.objectsService.getValue(this.card, 'documents');
  }
  get phone() {
    return this.objectsService.getValue(this.card, 'contact-phone');
  }
  get email() {
    return this.objectsService.getValue(this.card, 'contact-email');
  }
  get html() {
    return this.objectsService.getValue(this.card, 'html');
  }

  get textOnly(){
    return this.html?.replace(/<[^>]*>?/gm, '').replace(/\s/g, "").replace(/\&nbsp;/gm,'');
  }

  get about(){
    return this.pagesService.rootPage.slug ===""?"Подробнее":"About"
  }

  ngOnInit() {
  }
}

