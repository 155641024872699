// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {

  // production: true,
  // apiUrl: ' http://api-vniigaz-cert-v3.testnir.ru',
  // clientId: 2,
  // clientSecret: 'hqhpnNTBrvqiv4o85ZNCewOO8n3Y2i9iaRYGfMnt',
  // project: null,
  // licence: 'WMBTLLDIE6RC',
  // defaultLocale: 'ru'

  production: true,
  apiUrl: ' https://vniigaz-cert.ru',
  clientId: 2,
  clientSecret: 'hqhpnNTBrvqiv4o85ZNCewOO8n3Y2i9iaRYGfMnt',
  project: null,
  licence: 'WWFPAMDIANH3',
  defaultLocale: 'ru'

};





/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
