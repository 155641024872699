<div class="jumbotron-bg space" [ngStyle]="bgStyle" [class.rootpage-jumbotron]="isRootPage">
    <div class="jumbotron-content layout-corral">
        <header-user-bar dir="ltr"></header-user-bar>
        <div class="jumbotron-content-center">
            <h1 [ngStyle]="h1Style">{{name}}</h1>
            <!-- <h1 *ngIf="isRootPage" [ngStyle]="h1Style">
                Сертификация<br>
                продукции, работ и услуг</h1> -->
            <h4 *ngIf="since">
                <a href="https://vniigaz.gazprom.ru/" target="_blank">Учреждено в 1998</a>
            </h4>
        </div>
        
    </div>
</div>
<div class="space jumbotron-edit-field" *ngIf="editable" dir="ltr">
    <div class="layout-corral jumbotron-edit-field-row">
        <div class="switch-host" (click)="toggleEditMode()">
            Режим редактирования
            <switch [val]="editMode"></switch>       
        </div>         
    </div>
</div>
