import { Component } from '@angular/core';
import { PagesService } from '@app/_services/pages.service';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '@app/_services';

@Component({
  selector: 'jumbotron',
  templateUrl: './jumbotron.component.html',
  styleUrls: ['./jumbotron.component.scss']
})
export class JumbotronComponent {
  private subscription:Subscription 
  public currentPage:any;
  public pagePicture:any;



  constructor(
    private pagesService: PagesService,
    public authService: AuthenticationService,
  ){}

  ngOnInit(){
    this.subscription = this.pagesService.currentPageSubject.subscribe(
      resp=> {
        this.currentPage = resp;
        if (this.currentPage) {
          if(this.currentPage.picture){
            this.pagePicture = this.currentPage.picture.data.links.open
          }else{
            let parents = this.currentPage.parents.data
            let content = parents.map(page=>page.picture).filter(page=>page)
            this.pagePicture = content.pop()?.data.links.open
          }
        }else{this.pagePicture = null;}
      }
    )
  }
  ngOnDestoy(){
    this.subscription.unsubscribe();
  }

  get name(){
    let name:string
    if(this.currentPage){
      name = this.currentPage?.h1 || this.currentPage?.name;
      let parents = this.currentPage?.parents?.data
      if(parents){
        if (parents[1]?.type.name=='nav-page'){
          if (parents[2]) name = parents[2].h1|| parents[2].name
        }else{
          if (parents[1]) name = parents[1].h1|| parents[1].name
        }
      }
    }
    return name
  }

  get bgStyle(){
    let height = 70;
    let pictURL:string;
    if (this.pagePicture){
      height = 150;
      pictURL = this.pagePicture;
    }
    
    return {
      'background-image' : `url(${pictURL})`||'none',
      'height': (this.mainPage?700:pictURL?400:height) + 'px'
    }
  }

  get h1Style(){
    return { 
      color: this.pagePicture?'var(--white)':'var(--second-act)',
      'align-self': this.mainPage?'center':'flex-start',
      'text-align': this.mainPage?'center':'left',
      'width': this.mainPage?'900px':null,
      'border-bottom':this.mainPage?null:'12px solid #2EB2E8'
    }
  }

  get mainPage(){
    return this.currentPage?.parents?.data.length === 0;
  }

  get editable(){
    return this.currentPage&&(this.authService.privileges?.admin|| this.authService.privileges?.editor);
  }

  get editMode(){
    return this.pagesService.editMode;
  }

  toggleEditMode(){
    this.pagesService.editMode = !this.pagesService.editMode;
  }

  get isRootPage(){
    return !this.pagesService.currentPage?.parents.data.length && this.pagesService.currentPage
  }

  get since(){
    return this.currentPage?.link === '/'
  }

}
