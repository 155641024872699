import {Component, Input} from '@angular/core';
import { PagesService } from '@app/_services/pages.service';
import { Router } from '@angular/router';

@Component({
  selector: 'pages-menu-item',
  templateUrl: 'pages-menu-item.component.html',
  styleUrls: ['pages-menu-item.component.scss', '../pages-menu.component.scss']
})
export class PagesMenuItemComponent {
  @Input() item: any;
  @Input() level: number;
  open = false

  constructor(
    private pagesService: PagesService,
    private router: Router
  ) {}

  get children() {
    return this.item.children?.data;
  }

  get hasChildren(){
    return this.item.hasChildren
  }

  get current(){
    return this.item.link == this.pagesService.currentPage?.link
  }

  get selected(){
    return this.item.link == this.pagesService.menuSelectedLink
  }

  get parent(){
    return !!this.pagesService.currentPage?.parents?.data.find(
      (item:any) => item.id == this.item.id
    )
  }

  get nav(){
    return this.item.type.name == "nav-page"
  }

  get itemClass(){
    let resp = {
      'current': this.current,
      'parent': this.parent,
      'open': this.open,
      'selected': this.selected,
      'nav': this.nav,
      'children':this.hasChildren
    }
    resp['lvl-'+this.level] = true;
    return resp
  }

  get menuClass(){
    let resp = {
      'current': this.current,
      'parent': this.parent,
      'open': this.open,
      'selected': this.selected
    }
    resp['lvl-'+(this.level+1)] = true;
    return resp
  }

  findChildren(){
    if (this.item.hasChildren && !this.item.children){
      let include = [
        'children'
      ];
      this.pagesService.find(this.item.link, {include: include.join(',')}).subscribe(resp => {
        this.item.children = resp.data.children
      }, error => {
        console.log('error')
      });
    }
  }



  toggle(event){
    this.pagesService.menuSelectedLink = this.selected ? null : this.item.link
    this.open = event;
    if(event){
      this.findChildren()
    }
  }

  select(){
    if (this.item.type.name == 'nav-page'){
        this.toggle(!this.open);
    }else{
      this.pagesService.menuSelectedLink = this.selected ? null : this.item.link
      this.router.navigateByUrl(this.item.link)
    }
  }

  mouseEnter(){
    this.findChildren()
  }

  ngOnInit(){
    this.open = this.current || this.parent || this.selected
  }

}
