import {Component} from '@angular/core';
import {AuthenticationService} from "@app/_services";
import {PagesService} from "@app/_services/pages.service";
import {Router} from "@angular/router";

@Component({
  selector: 'footer',
  templateUrl: 'footer.component.html',
  styleUrls: ['footer.component.scss']
})
export class FooterComponent {

  public year = new Date().getFullYear();

  constructor(
    public authService: AuthenticationService,
    public pagesService: PagesService,
    private router: Router) {
  }

  get isLoggedIn() {
    return this.authService.isLoggedIn;
  }

  ngOnInit() {
  }

  login() {
    this.authService.popup('login');
  }

  get isRusPage(){
    return this.pagesService.rootPage.slug ===""
  }

  get rootPage() {
    return this.pagesService.rootPage;
  }

  rootNav(){
    this.router.navigate([this.rootPage.link]).then(()=>window.scrollTo(0,0))
  }



}
