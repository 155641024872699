import { Component } from '@angular/core';
import { PagesService } from '@app/_services/pages.service';

@Component({
  selector: 'right-content',
  templateUrl: './right-content.component.html',
  styleUrls: ['./right-content.component.scss']
})
export class RightContentComponent {
  constructor(
    private pagesService:PagesService
  ){}
  get show(){
    return !!this.pagesService.currentPage
  }
  
}
