<div class="search">
  <h1>Поиск</h1>
  <div class="filters" [formGroup]="filters">
    <div class="limiter">
      <div class="search-form">
        <label>Поиск</label>
        <input type="text" formControlName="search" />
      </div>
    </div>
  </div>
</div>
<div class="results" *ngIf="searchString">
  <div class="tabs default" *ngIf="total">
    <div>
      <button *ngFor="let tab of tabs" type="button" [class.disabled]="tab.disabled" [class.active]="tab.active" (click)="toggleTab(tab)">{{tab.title}} ({{tab.count || 0}})</button>

    </div>
  </div>
  <div class="result">
    <search-sections [class.hidden]="activeTab.name !== 'pages'"></search-sections>
    <publications-list [class.hidden]="activeTab.name !== 'publications'"></publications-list>
    <registry-entries [class.hidden]="activeTab.name !== 'entries'"></registry-entries>
  </div>
</div>
<div class="empty" *ngIf="searchString && !total && isLoaded">По запросу "{{searchString}}" результатов не найдено. Попробуйте задать менее строгий запрос</div>
