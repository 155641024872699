<div class="header layout-corral" [class.rootpage]="isRootPage">
  <a class="burger" (click)="openMobileMenu()">
    <ico *ngIf="!isRootPage" ico="burger_blue_40dp" [size]="36"></ico>
    <ico *ngIf="isRootPage" ico="burger_white_40dp" [size]="36"></ico>
  </a>
  <a [routerLink]="rootPage?.link" class="logo">
    <img *ngIf="!isRootPage" src="assets/images/logo_vniigaz.svg" alt="ВНИИГАЗ">
    <img *ngIf="isRootPage" src="assets/images/logo_vniigaz_wt_280x110.svg" alt="ВНИИГАЗ">
  </a>

  <pages-menu class="lvl-0"></pages-menu>

  <div class="inline center header-right">
    <locale></locale>
    <a class="btn vnii" href="https://ais.vniigaz-cert.ru/" target="_blank" *ngIf="isRusPage">Личный кабинет (АИС СЦ)</a>
  </div>
  
</div>
