<div class="main">
  <div class="block row">
    <div class="name">
      <div *ngIf="link">        
        <a [href]="link" target="_blank">
          <ico ico="document_24"></ico> {{title}}
        </a>
      </div>
      <span *ngIf="!link">{{title}}</span>
    </div>
    <drop-down *ngIf="isExpandable" (click)="toggle()" [visible]="isExpanded"></drop-down>
  </div>
  <div class="block" *ngIf="registryOptions?.states && activeSince">
    <div class="state {{entry.state?.name}}">{{entry.state?.title}}</div>
    <div class="dates">
      <table class="default">
        <tr><td class="caption">Дата выдачи</td><td class="value">{{activeSince || '—'}}</td></tr>
        <tr><td class="caption">Срок действия</td><td class="value">{{activeTill || '—'}}</td></tr>
      </table>
    </div>
  </div>
  <div class="block" *ngIf="editMode">
    <div class="menu menu-entry">
      <ico ico="drag-n-drop_24" class="move" title="Переместить"></ico>
      <ico ico="edit_24" title="Редактировать" (click)="edit()"></ico>
      <!-- <ico ico="add_notes_24" title="Добавить запись" (click)="add()"></ico> -->
      <ico ico="delete_24" title="Удалить категорию" (click)="delete()"></ico>
    </div>
  </div>
</div>
<div class="details" *ngIf="isExpanded">
  <div class="block" *ngIf="registryOptions?.properties">
    <entry-properties [entry]="entry"></entry-properties>
  </div>
  <div class="block" *ngIf="registryOptions?.operations">
    <entry-operations [entry]="entry" [editMode]="editMode"></entry-operations>
  </div>
</div>
<!-- <pre>{{entry|json}}</pre> -->