import {Component, Input} from '@angular/core';
import {Subscription} from "rxjs";
import {RegistryEntriesService} from "@app/_services/registry-entries.service";
import {ListsService} from "@app/_services";
import {SortableOptions} from "sortablejs";

@Component({
  selector: 'registry-entries',
  templateUrl: 'registry-entries.component.html',
  styleUrls: ['registry-entries.component.scss']
})
export class RegistryEntriesComponent {
  @Input() registry: any;
  @Input() category: any;
  @Input() editMode: boolean;
  public entries = <any>[];

  public options: SortableOptions = {
    group: 'registry-entries',
    handle: '.move',
    sort: false,
    onAdd: (event: any) => {
      this.move(event);
    }
  };

  private controlsSubscription: Subscription;
  private resultSubscription: Subscription;

  constructor(private entriesService: RegistryEntriesService, private listsService: ListsService) {
  }

  get listId() {
    return (this.category?.id || this.registry?.id || 'common') + '-entries';
  }

  ngOnInit() {
    this.listsService.addFilters({registry: this.registry?.id, category: this.category?.id}, this.listId);
    this.controlsSubscription = this.listsService.controls(this.listId).subscribe(controls => {
      this.fetch(controls);
    });
    this.resultSubscription = this.listsService.result(this.listId).subscribe(res => {
      this.entries = res?.data || [];
    });
  }

  ngOnDestroy() {
    this.controlsSubscription?.unsubscribe();
    this.resultSubscription?.unsubscribe();
  }

  fetch(controls: any) {
    let include = ['asset'];
    if (!this.registry) include.push('registry');
    console.log('controls', controls)
    this.entriesService.list({page: controls.page || 0, filters: JSON.stringify(controls.filters), include: include.join(',')}).subscribe(res => {
      this.listsService.result(this.listId).next(res);
    });
  }


  move(event: any) {
    this.entriesService.move(event.item.id, {category: this.category?.id}).subscribe(res => {
    });
  }


}
