import {Component} from '@angular/core';
import {Router} from "@angular/router";
import {PagesService} from "@app/_services/pages.service";

@Component({
  selector: 'header',
  templateUrl: 'header.component.html',
  styleUrls: ['header.component.scss']
})
export class HeaderComponent {

  constructor(
    private router: Router,
    public pagesService: PagesService
    ) {
  }
  // ngOnInit(){
  //   this.pagesService.root().subscribe(
  //     resp=> this.pagesService.rootPages = resp
  //   )
  // }

  get rootPage() {
    return this.pagesService.rootPage;
  }

  get isRootPage(){
    return !this.pagesService.currentPage?.parents.data.length && this.pagesService.currentPage
  }

  get isRusPage(){
    return this.rootPage.slug ===""
  }

  openMobileMenu(){
    this.router.navigate([{outlets: {slider: 'pages-menu'}}], {skipLocationChange: true}).then();
  }
}