<div class="footer layout-corral" *ngIf="isRusPage">
  <div class="block">
    <div class="logo">
      <a class="footer-logo-img" (click)="rootNav()">
        <img src="assets/images/logo_vniigaz_wt_280x110.svg" alt="">
      </a>
      <div  *ngIf="!isLoggedIn" class="footer-login">
        <a (click)="login()">Вход в личный кабинет</a>
      </div>
    </div>
    <div class="contact">
      <div>
        <a href="tel:+74986574518" dir="ltr">+7&nbsp;(498)&nbsp;657-45-18</a>
      </div>
      <div>
        <a href="mailto:info@vniigaz-cert.ru">info@vniigaz-cert.ru</a>
      </div>
      <div>
        <span  dir="ltr">
          142717, Московская&nbsp;область, г.о.&nbsp;Ленинский, п&nbsp;Развилка, ул&nbsp;Газовиков, зд.&nbsp;15,&nbsp;стр.&nbsp;11
        </span>
      </div>
      <div class="icons">
        <a href="https://vk.com/vniigazcert" target="_blank">
          <img src="assets/images/icons/vk_white_29x28.svg" alt="">
        </a>
        <a href="https://ok.ru/group/59746745647125" target="_blank">
          <img src="assets/images/icons/ok_orange_28x28.svg" alt="">
        </a>
        <a href="https://dzen.ru/vniigazcert" target="_blank">
          <img src="assets/images/icons/zen_black_28x28.svg" alt="">
        </a>
      </div>

    </div>
  </div>
  <div class="line"></div>
  <div class="copyright">
    <div>
      © 1998–{{year}} ЧУ СЦ ВНИИГАЗ-Сертификат
    </div>
    <div class="nir">
      <a href="https://nirgroup.ru">Работает на платформе НИР</a>
    </div>
  </div>
</div>


<div class="footer layout-corral" *ngIf="!isRusPage">
  <div class="block">
    <div class="logo">
      <a  (click)="rootNav()" class="footer-logo-img">
        <img src="assets/images/logo_vniigaz_wt_280x110.svg" alt="">
      </a>
      <!-- <div  *ngIf="!isLoggedIn" class="footer-login">
        <a (click)="login()">Вход в личный кабинет</a>
      </div> -->
    </div>
    <div class="contact">
      <div>
        <a href="tel:+74986574518" dir="ltr">+7&nbsp;(498)&nbsp;657-45-18</a>
      </div>
      <div>
        <a href="mailto:info@vniigaz-cert.ru">info@vniigaz-cert.ru</a>
      </div>
      <div>
        <span  dir="ltr">
          Gazovikov&nbsp;str.&nbsp;15&nbsp;building&nbsp;11, Razvilka&nbsp;settl., Leninsky&nbsp;d-t, Moscow&nbsp;region, Russian&nbsp;Federation, 142717 
        </span>
      </div>
      <div class="icons">
        <a href="https://vk.com/vniigazcert" target="_blank">
          <img src="assets/images/icons/vk_white_29x28.svg" alt="">
        </a>
        <a href="https://ok.ru/group/59746745647125" target="_blank">
          <img src="assets/images/icons/ok_orange_28x28.svg" alt="">
        </a>
        <a href="https://dzen.ru/vniigazcert" target="_blank">
          <img src="assets/images/icons/zen_black_28x28.svg" alt="">
        </a>
      </div>

    </div>
  </div>
  <div class="line"></div>
  <div class="copyright">
    <div>
      © 1998–{{year}} Private Institution Certification Center VNIIGAZ-Certificate
    </div>
    <div class="nir">
      <a href="https://nirgroup.ru">Powered by the NIR platform</a>
    </div>
  </div>
</div>
