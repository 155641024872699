import {Component} from '@angular/core';
import {Subscription} from "rxjs";
import {ListsService} from "@app/_services";
import { PagesService } from '@app/_services/pages.service';

@Component({
  selector: 'grid',
  templateUrl: 'grid.component.html',
  styleUrls: ['grid.component.scss']
})
export class GridComponent {

  public classActive = '';

  public bright: number = 150;
  public contrast: number = 100;

  subscription?: Subscription;

  constructor(
    private listsService: ListsService, private pagesService: PagesService
  ) {
  }

  ngOnInit() {
    this.subscription = this.listsService.result('class').subscribe(val => {
      this.classActive = val;
    });
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  get isRootPage(){
    return this.pagesService.currentPage && this.pagesService.currentPage.parents && this.pagesService.currentPage.parents.data.length == 0
  }

}
